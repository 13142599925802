<template>
    <div class="main-content ">
        <el-row class="cart-header">
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                <el-steps :active="1" finish-status="success" simple style="margin-top: 20px;margin-right: 1%;margin-left: 1%">
                    <el-step title="購物車"></el-step>
                    <el-step title="結帳"></el-step>
                    <el-step title="訂單完成"></el-step>
                </el-steps>
            </el-col>
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
        </el-row>
        <!-- p-->
        <el-row class="hidden-sm-and-down">
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
            <el-col :lg="12" :md="12" :sm="24" :xs="24">
                <el-card style="margin: 10px;" shadow="hover">
                    <el-row style="font-weight: bold;color: #909399;margin-bottom: 10px;">
                        <el-col :lg="3">操作</el-col>
                        <el-col :lg="12">商品</el-col>
                        <el-col :lg="3">價格</el-col>
                        <el-col :lg="3" style="text-align: center">數量</el-col>
                        <el-col :lg="3" style="text-align: right">小計</el-col>
                    </el-row>

                    <div v-if="cartList.length>0">
                        <el-row v-for="(item, index) in cartList" :key="index" class="cart-item">
                            <el-col :lg="3" type="flex" align="left">
                                <el-button
                                        icon="el-icon-close"
                                        type="danger"
                                        circle
                                        size="mini"
                                        style="font-weight: bold;color: white"
                                        @click="handleDelCartItem(item)">

                                </el-button>
                            </el-col>
                            <el-col :lg="12">
                                <div style="display: flex;justify-content: start;align-items: center;cursor:pointer;" @click="go2ProductDtl(item.productId);">
                                    <span><img :src="item.image" style="max-width: 80px;max-height: 100%;border-radius: 3px"></span>
                                    <span style="padding: 0px 10px">{{item.storeName}}</span>
                                </div>
                            </el-col>
                            <el-col :lg="3">{{item.price|filter_addPricePrefix}}</el-col>
                            <el-col :lg="3">
                                <el-input-number
                                        size="mini"
                                        v-model="item.cartNum"
                                        @change="handleChangeCartNum(item)"
                                        :min="1"
                                        :max="10">
                                </el-input-number>
                            </el-col>
                            <el-col :lg="3" style="text-align: right"><span class="price">{{item.cartNum*item.price |filter_addPricePrefix}}</span></el-col>
                        </el-row>
                    </div>
                    <div v-else v-show="!cartEmptShow">
                        <el-skeleton />
                    </div>
                    <div v-show="cartEmptShow">
                        <el-row>
                            <el-col :lg="24" :xs="24" style="text-align: center;height: 100%">
                                <el-empty :image-size="100" description="暫無數據"></el-empty>
                                <h3>再忙也要好好犒勞下自己，快去
                                    <router-link to="/category">裝滿購物車</router-link>
                                    吧？
                                </h3>
                            </el-col>
                        </el-row>

                    </div>
                </el-card>

            </el-col>
            <el-col :lg="6" :md="6" :sm="24" :xs="24">
                <el-card style="margin: 10px;" class="cart-total-card" shadow="hover">
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>運送方式</h3></el-col>
                        <el-col :lg="24" :xs="24">
                            <el-radio-group v-model="shippingType">
                                <el-radio :label="3">超商取貨</el-radio>
                                <!--<el-radio disabled :label="4">货到付款</el-radio>-->
                            </el-radio-group>
                        </el-col>
                        <el-col :lg="24" :xs="24"><p>超商門市</p></el-col>
                        <el-col :lg="24" :xs="24">

                            <el-tag v-if="szfuStoreInfo.storeNo" type="plain" style="margin-bottom: 5px;font-weight: bold">
                                <span style="padding-right: 10px">{{szfuStoreInfo.storeName}}</span>
                                <span>{{szfuStoreInfo.storeAddress}}</span>
                            </el-tag>
                            <span style="color: red;" v-else>請選擇取貨門店</span>
                        </el-col>
                        <el-col :lg="24" :xs="24">
                            <form ref="form" :action="mapUrl" method="post">
                                <input type="hidden" name="BacksURL" :value="currUrl">
                                <input type="hidden" name="Phone" value="0">
                                <button @click="cvsSubmitForm" class="el-button el-button--plan">
                                    <img style="display: block" src="https://emap.pcsc.com.tw/ecmap/images/logo.png"/>
                                    <div style="font-weight: bold;font-size: 20px">選擇超商門市</div>
                                </button>
                            </form>
                        </el-col>
                        <el-col>
                            <el-form size="small">
                                <el-form-item label="收件人">
                                    <el-input v-model="rcvform.rcvName"></el-input>
                                </el-form-item>
                                <el-form-item label="聯絡電話">
                                    <el-input v-model="rcvform.rcvPhone"></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :lg="24" :xs="24"><h3>付款方式</h3>
                        </el-col>
                        <el-col :lg="24" :xs="24">
                            <el-form ref="payChannel" label-width="80px" label-position="top">
                                <el-form-item label="">
                                    <div style="line-height: 40px;"><img :src="szfuLogo" style="height: 25px;"/></div>
                                    <el-select v-model="payChannel" placeholder="請選擇付款方式">
                                        <el-option-group
                                                v-for="group in options"
                                                :key="group.label"
                                                :label="group.label">
                                            <el-option
                                                    v-for="item in group.options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-option-group>

                                        <!--<el-option label="ATM虛擬帳號轉帳" value="ATM"></el-option>
                                        <el-option label="超商代碼繳費" value="CVS"></el-option>-->
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>購物車總計</h3>
                        </el-col>
                    </el-row>
                    <el-row class="cart-total-item">
                        <el-col :lg="12" :xs="12">小計</el-col>
                        <el-col :lg="12" :xs="12"><span class="total-amt">{{totalAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>

                    <el-row class="cart-total-item">
                        <el-col :lg="12" :xs="12">運费</el-col>
                        <el-col :lg="12" :xs="12">
                            <div><span class="total-amt">{{freightFee |filter_addPricePrefix}}</span></div>
                        </el-col>
                    </el-row>

                    <el-card shadow="hover" v-if="1>2">
                        <div slot="header" class="clearfix">
                            <span>收貨地址</span>
                        </div>
                        <div>
                            <el-row v-if="rcvAddress.id>0">
                                <el-col :lg="21" :xs="21">
                                    <div>
                                        {{rcvAddress.city}} {{rcvAddress.district}} {{rcvAddress.detail}}
                                    </div>
                                    <div>
                                        {{rcvAddress.realName}} {{rcvAddress.phone}}
                                    </div>
                                </el-col>
                                <el-col :lg="3" :xs="3">
                                    <div style="margin-left: 10px;">
                                        <el-button size="mini" type="text" @click="chgAddress">變更</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row v-else>
                                <el-button type="danger" size="mini" @click="addAddress" align="right">新增地址</el-button>
                            </el-row>
                            <el-row v-show="chgAddressFlg">
                                <div class="address-wrap">
                                    <ul v-if="addressList.length>0">
                                        <el-divider></el-divider>
                                        <el-button type="danger" size="mini" @click="addAddress" align="right">新增地址</el-button>
                                        <el-radio-group v-model="sctAddressId" @change="handleChangeAddress">
                                            <li v-for="(item,index) in addressList" :key="index" class="address-item">
                                                <el-radio :label="item.id" size="mini" class="address-radio">
                                                    <div>
                                                        <div style="word-break:normal; width:auto; display:block; white-space:pre-wrap;word-wrap : break-word ;overflow: hidden ;">
                                                            <el-tag type="warning" size="mini">{{item.postCode}}</el-tag>
                                                            <span>{{item.city}} {{item.district}} {{item.detail}}</span>
                                                            <el-tag v-show="item.isDefault" type="danger" size="mini">默認</el-tag>
                                                        </div>
                                                        <div>
                                                            <el-tag type="info" size="mini">{{item.realName}}</el-tag>
                                                            <el-tag type="info" size="mini">{{item.phone}}</el-tag>
                                                        </div>
                                                    </div>
                                                </el-radio>
                                            </li>
                                        </el-radio-group>
                                    </ul>
                                    <ul v-show="showAddFlg">
                                        <p style="text-align: center">還沒有地址，請
                                            <el-button type="text" size="mini" @click="addAddress">新增地址</el-button>
                                        </p>
                                    </ul>
                                </div>
                            </el-row>
                        </div>
                    </el-card>
                    <el-divider></el-divider>
                    <el-row class="cart-total-item cart-total">
                        <el-col :lg="12" :xs="12">總計</el-col>
                        <el-col :lg="12" :xs="12"><span class="total-amt">{{totalSumAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                        <el-button type="warning" @click="go2Shop">回賣場</el-button>
                        <el-button type="danger" @click="go2CheckOut" :disabled="checkOutDisable">去結帳</el-button>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :lg="3" :md="3" class="hidden-sm-and-down">
                &nbsp;
            </el-col>
        </el-row>
        <!--p over-->

        <!--m  start-->
        <el-row class="hidden-md-and-up">
            <el-col :sm="24" :xs="24">
                <el-card style="margin: 0px 8px 0px 8px;">
                    <div v-if="cartList && cartList.length>0">
                        <el-row v-for="(item, index) in cartList" :key="index" class="cart-item cart-item-mobile">
                            <el-col :sm="6" :xs="6">
                                <div style="display: flex;justify-content: start;align-items: center;" @click="go2ProductDtl(item.productId);">
                                    <span><img :src="item.image" style="max-width: 80px;max-height: 100%;border-radius: 3px"></span>
                                </div>
                            </el-col>
                            <el-col :sm="18" :xs="18">
                                <el-row>
                                    <el-col :sm="18" :xs="18">{{item.storeName}}</el-col>
                                    <el-col :sm="6" :xs="6" align="right">
                                        <el-button
                                                icon="el-icon-close"
                                                type="danger"
                                                circle
                                                size="mini"
                                                style="font-weight: bold;color: white"
                                                @click="handleDelCartItem(item)">
                                        </el-button>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :sm="18" :xs="18">價格</el-col>
                                    <el-col :sm="6" :xs="6" align="right">{{item.price|filter_addPricePrefix}}</el-col>
                                </el-row>
                                <el-row>
                                    <el-col :sm="16" :xs="16">數量</el-col>
                                    <el-col :sm="8" :xs="8" align="right">
                                        <el-input-number
                                                size="mini"
                                                v-model="item.cartNum"
                                                @change="handleChangeCartNum(item)"
                                                :min="1"
                                                :max="10">
                                        </el-input-number>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :sm="16" :xs="16">小計</el-col>
                                    <el-col :sm="8" :xs="8" type="flex" align="right"><span class="price">{{item.cartNum*item.price |filter_addPricePrefix}}</span></el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </div>
                    <div v-else v-show="!cartEmptShow">
                        <el-skeleton />
                    </div>
                    <div v-show="cartEmptShow">
                        <el-row>
                            <el-col :lg="24" :xs="24" style="text-align: center;height: 100%">
                                <el-empty :image-size="100" description="暫無數據"></el-empty>
                                <h4>再忙也要好好犒勞下自己，快去
                                    <router-link to="/category">裝滿購物車</router-link>
                                    吧？
                                </h4>
                            </el-col>
                        </el-row>

                    </div>
                </el-card>
            </el-col>
            <el-col v-if="cartList && cartList.length>0" :sm="24" :xs="24">
                <el-card style="margin: 10px;" class="cart-total-card">
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>運送方式</h3></el-col>
                        <el-col :lg="24" :xs="24">
                            <el-radio-group v-model="shippingType">
                                <el-radio :label="3">超商取貨</el-radio>
                                <!--<el-radio disabled :label="4">货到付款</el-radio>-->
                            </el-radio-group>
                        </el-col>
                        <el-col :lg="24" :xs="24"><p>超商門市</p></el-col>
                        <el-col :lg="24" :xs="24">

                            <el-tag v-if="szfuStoreInfo.storeNo" type="plain" style="margin-bottom: 5px;font-weight: bold">
                                <span style="padding-right: 10px">{{szfuStoreInfo.storeName}}</span>
                                <span>{{szfuStoreInfo.storeAddress}}</span>
                            </el-tag>
                            <span style="color: red;" v-else>請選擇取貨門店</span>
                        </el-col>
                        <el-col :lg="24" :xs="24">
                            <form ref="form" :action="mapUrl" method="post">
                                <input type="hidden" name="BacksURL" :value="currUrl">
                                <input type="hidden" name="Phone" value="1">
                                <button @click="cvsSubmitForm" class="el-button el-button--plan">
                                    <img style="display: block" src="https://emap.pcsc.com.tw/ecmap/images/logo.png"/>
                                    <div style="font-weight: bold;font-size: 20px">選擇超商門市</div>
                                </button>
                            </form>
                        </el-col>
                        <el-col>
                            <el-form size="small">
                                <el-form-item label="收件人">
                                    <el-input v-model="rcvform.rcvName"></el-input>
                                </el-form-item>
                                <el-form-item label="聯絡電話">
                                    <el-input v-model="rcvform.rcvPhone"></el-input>
                                </el-form-item>
                            </el-form>
                        </el-col>
                        <el-col :lg="24" :xs="24"><h3>付款方式</h3>
                        </el-col>
                        <el-col :lg="24" :xs="24">
                            <el-form ref="payChannel" label-width="80px" label-position="top">
                                <el-form-item label="">
                                    <div style="line-height: 40px;"><img :src="szfuLogo" style="height: 25px;"/></div>
                                    <el-select v-model="payChannel" placeholder="請選擇付款方式">
                                        <el-option-group
                                                v-for="group in options"
                                                :key="group.label"
                                                :label="group.label">
                                            <el-option
                                                    v-for="item in group.options"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                            </el-option>
                                        </el-option-group>

                                        <!--<el-option label="ATM虛擬帳號轉帳" value="ATM"></el-option>
                                        <el-option label="超商代碼繳費" value="CVS"></el-option>-->
                                    </el-select>
                                </el-form-item>
                            </el-form>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :lg="24" :xs="24"><h3>購物車總計</h3>
                        </el-col>
                    </el-row>
                    <el-row class="cart-total-item">
                        <el-col :lg="12" :xs="12">小計</el-col>
                        <el-col :lg="12" :xs="12"><span class="total-amt">{{totalAmt |filter_addPricePrefix}}</span></el-col>
                    </el-row>

                    <el-row class="cart-total-item">
                        <el-col :lg="12" :xs="12">運费</el-col>
                        <el-col :lg="12" :xs="12">
                            <div><span class="total-amt">{{freightFee |filter_addPricePrefix}}</span></div>
                        </el-col>
                    </el-row>

                    <el-card shadow="hover" v-if="1>2">
                        <div slot="header" class="clearfix">
                            <span>收貨地址</span>
                        </div>
                        <div>
                            <el-row v-if="rcvAddress.id>0">
                                <el-col :lg="21" :xs="21">
                                    <div>
                                        {{rcvAddress.city}} {{rcvAddress.district}} {{rcvAddress.detail}}
                                    </div>
                                    <div>
                                        {{rcvAddress.realName}} {{rcvAddress.phone}}
                                    </div>
                                </el-col>
                                <el-col :lg="3" :xs="3">
                                    <div style="margin-left: 10px">
                                        <el-button size="mini" type="text" @click="chgAddress">變更</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row v-else>
                                <el-button type="danger" size="mini" @click="addAddress" align="right">新增地址</el-button>
                            </el-row>

                            <el-row v-show="chgAddressFlg">
                                <div class="address-wrap">
                                    <ul v-if="addressList.length>0">
                                        <el-divider></el-divider>
                                        <el-col :xs="24">
                                            <el-button type="danger" size="mini" @click="addAddress" align="right">新增地址</el-button>
                                        </el-col>
                                        <el-col :xs="24">
                                            <el-radio-group v-model="sctAddressId" @change="handleChangeAddress">
                                                <li v-for="(item,index) in addressList" :key="index" class="address-item">
                                                    <el-radio :label="item.id" size="mini" class="address-radio">
                                                        <div>
                                                            <div style="word-break:normal; width:auto; display:block; white-space:pre-wrap;word-wrap : break-word ;overflow: hidden ;">
                                                                <el-tag type="warning" size="mini">{{item.postCode}}</el-tag>
                                                                <span>{{item.city}} {{item.district}} {{item.detail}}</span>
                                                                <el-tag v-show="item.isDefault" type="danger" size="mini">默認</el-tag>
                                                            </div>
                                                            <div>
                                                                <el-tag type="info" size="mini">{{item.realName}}</el-tag>
                                                                <el-tag type="info" size="mini">{{item.phone}}</el-tag>
                                                            </div>
                                                        </div>
                                                    </el-radio>
                                                </li>
                                            </el-radio-group>
                                        </el-col>
                                    </ul>
                                    <ul v-show="showAddFlg">
                                        <p style="text-align: center">還沒有地址，請
                                            <el-button type="text" size="mini" @click="addAddress">新增地址</el-button>
                                        </p>
                                    </ul>
                                </div>
                            </el-row>
                        </div>
                    </el-card>
                    <el-divider></el-divider>
                    <el-row class="cart-total-item cart-total">
                        <el-col :lg="12" :xs="12">總計</el-col>
                        <el-col :lg="12" :xs="12"><span class="total-amt">{{totalSumAmt|filter_addPricePrefix}}</span></el-col>
                    </el-row>
                    <el-row type="flex" justify="center">
                        <el-button type="warning" @click="go2Shop">回賣場</el-button>
                        <el-button type="danger" @click="go2CheckOut" :disabled="checkOutDisable">去結帳</el-button>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!---mobile over-->

        <AddressDialog :dialogFormVisible="dialogFormVisible"
                       :editRow="editRow"
                       @resetEditRow="resetEditRow"
                       @dialogVisible="dialogVisible"
                       @refreshAddressList="getAddressList"
        ></AddressDialog>
    </div>
</template>

<script>
    import {CartDelete, CartNum, GetAddressList, GetCartList, GetDefaultAddress,GetRcvDefaultAddress,GetSzfuMapStoreInfo} from '@/request/api';
    import AddressDialog from '@/components/AddressDialog.vue';

    export default {
        components: {AddressDialog},
        data() {
            return {
                checkOutDisable:false,
                limit: 50,
                page: 1,
                cartEmptShow:false,
                cartList: [],
                totalAmt: 0,
                totalQty: 0,
                totalSumAmt: 0,
                freightFee: 0,
                showAddFlg:false,//当没有地址时显示新增按钮
                rcvAddress: {},
                addressList: [],//地址簿
                chgAddressFlg: false,//修改地址开关
                sctAddressId: 0,
                formLabelAlign: {
                    name: '',
                    region: '',
                    type: ''
                },
                editRow: undefined,
                dialogFormVisible: false,
                shippingType: 3,//3超商取货,4货到付款
                payChannel: 'ATM',
                options: [{
                    label: 'ATM轉帳',
                    options: [{
                        value: 'ATM',
                        label: 'ATM虛擬帳號轉帳'
                    }]
                }, {
                    label: '超商代繳',
                    options: [{
                        value: 'IBONS',
                        label: '7-11超商代碼繳費'
                    },{
                        value: 'FAMILYS',
                        label: '全家超商代碼繳費'
                    }, {
                        value: 'OKMART',
                        label: 'OK超商代碼繳費'
                    }, {
                        value: 'HILIFE',
                        label: '萊爾富超商代碼繳費'
                    },{
                        value: 'CVS',
                        label: '超商代碼繳費'
                    }]
                }],
                szfuLogo: require('@/assets/images/szfu_logo.png'),
                rcvform: {rcvName: "", rcvPhone: ""},
                szfuStoreInfo: {},
                mapUrl: '',
                currUrl: window.location.origin+"/cart",
            };
        },
        methods: {
            go2ProductDtl(id) {
                this.$router.push('/product/' + id);
            },
            handleChangeAddress(id) {
                this.rcvAddress = this.addressList.find(item => item.id === id);
                this.$store.commit('SET_RCVADDRESS', this.rcvAddress);
            },
            getDefaultAddress() {
                GetDefaultAddress({}).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data != null) {
                            this.rcvAddress = res.data;
                            this.sctAddressId = res.data.id;
                            this.$store.commit('SET_RCVADDRESS', this.rcvAddress);
                        } else {
                            this.$message.error('還未設定收貨地址!');
                            return;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.error(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            },
            getRcvDefaultAddress() {
                GetRcvDefaultAddress({cnno:'7'}).then(res => {
                    if (res.code == 200) {
                        if (res.data && res.data != null) {
                            this.mapUrl = res.data.mapUrl;
                            this.szfuStoreInfo.storeNo = res.data.storeNo;
                            this.szfuStoreInfo.storeName = res.data.storeName;
                            this.szfuStoreInfo.storeAddress = res.data.storeAddress;
                            this.rcvform.rcvName=res.data.realName;
                            this.rcvform.rcvPhone=res.data.phone;
                        } else {
                            this.$message.error('網絡請求失敗，稍候請重試!!');
                            return;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.error(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            },
            refreshAddress() {
                this.getAddressList();
                this.getDefaultAddress();
            },
            getAddressList() {
                GetAddressList({page: 1, limit: 10}).then(res => {
                    if (res.code == 200) {
                        this.addressList = res.data.list;
                        if (this.addressList.length <= 0) {
                            this.showAddFlg = true;
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.error(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            },
            chgAddress() {
                if (this.chgAddressFlg == false) {
                    this.getAddressList();
                }
                this.chgAddressFlg = !this.chgAddressFlg;
            },
            addAddress() {
                this.editRow = undefined;
                this.dialogFormVisible = true;
            },
            getCartList() {
                // const loading = this.$loading({
                //     lock: true,
                //     text: "加載中...",
                //     spinner: "el-icon-loading",
                //     background: "rgba(0, 0, 0, 0.5)"
                // });

                GetCartList({limit: this.limit, page: this.page, isValid: true}).then(res => {
                    //loading.close();
                    if (res.code == 401) {
                        this.$router.push('/login');
                        return;
                    }
                    if (res.code == 200) {
                        this.cartList = res.data.cartInfoList;
                        if (this.cartList == undefined) {
                            this.cartList = [];
                            this.resetData();
                        }
                        if (this.cartList.length <= 0) {
                            this.cartEmptShow = true;
                            this.resetData();
                            return;
                        } else {
                            this.cartEmptShow = false;
                        }
                        this.totalAmt = res.data.totalAmt;
                        this.totalQty = res.data.totalQty;
                        this.totalSumAmt = res.data.totalSumAmt;
                        this.freightFee = res.data.freightFee;
                        this.$store.commit('SET_CARTNUM', res.data.totalQty);
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    //loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            resetData() {
                this.totalAmt = 0;
                this.totalQty = 0;
                this.totalSumAmt = 0;
                this.freightFee = 0;
                this.cartEmptShow = true;
                this.cartList = [];
                this.checkOutDisable = true;
                this.$store.commit('SET_CARTNUM', 0);
            },
            handleDelCartItem(item) {
                let _this = this;
                this.$confirm('確定要删除嗎？')
                    .then(() => {
                        let arr = [];
                        arr.push(item.id);
                        let params = new URLSearchParams();
                        params.append('ids', arr.join(','));
                        CartDelete(params).then(res => {
                            console.log(res);
                            if (res.code == 200) {
                                _this.getCartList();
                            } else {
                                _this.$message.error(res.message);
                            }
                        }).catch(err => {
                            console.log(err);
                            _this.$message.error('網路連線失敗，稍候請重試!');
                        });
                    }).catch(() => {
                });
            },
            go2CheckOut() {
                /*if (this.sctAddressId && this.sctAddressId > 0) {
                    this.$router.push('/checkOut?ad=' + this.sctAddressId);
                } else {
                    this.$message.error('您還未設定收貨地址哦!');
                }*/
                if (!this.szfuStoreInfo || this.szfuStoreInfo.storeNo == null) {
                    this.$message.error('您還沒有選取取貨門店!');
                    return;
                }
                let req = {
                    storeNo: this.szfuStoreInfo.storeNo,
                    storeName: this.szfuStoreInfo.storeName,
                    storeAddress: this.szfuStoreInfo.storeAddress,
                    rcvName: this.rcvform.rcvName,
                    rcvPhone: this.rcvform.rcvPhone,
                    payChannel: this.payChannel,
                    shippingType: this.shippingType,
                    cnno: '7'
                };
                if (this.szfuStoreInfo && this.szfuStoreInfo.storeNo != null) {
                    this.$router.push({
                        name: 'CheckOut',
                        params: {
                            req: req
                        }
                    });
                } else {
                    this.$message.error('您還沒有選取取貨門店哦!');
                }
            },
            go2Shop() {
                this.$router.push('/category');
            },
            handleChangeCartNum(item) {
                let _this = this;
                let params = new URLSearchParams();
                params.append('id', item.id);
                params.append('number', item.cartNum);
                CartNum(params).then(res => {
                    if (res.code == 200) {
                        _this.getCartList();
                    } else {
                        _this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    _this.$message.error('網路連線失敗，稍候請重試!');
                });
            },
            dialogVisible(status) {
                this.dialogFormVisible = status;
            },
            resetEditRow() {
                this.editRow = {};
            },
            cvsSubmitForm() {
                this.$refs.form.submit();
            },
            getStoreInfo(maps) {
                const loading = this.$loading({
                    lock: true,
                    text: "加載中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.5)"
                });
                GetSzfuMapStoreInfo({maps: maps}).then(res => {
                    loading.close();
                    if (res.code == 200) {
                        this.szfuStoreInfo = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    loading.close();
                    console.log(err);
                    this.$message.error('網路連線失敗，稍候請重試!');
                });

            }
        },
        mounted() {
            this.getCartList();
           /* if (this.$store.state.rcvAddress && this.$store.state.rcvAddress.id > 0) {
                this.rcvAddress = this.$store.state.rcvAddress;
                this.sctAddressId = this.rcvAddress.id;
            } else {
                this.getDefaultAddress();
            }*/
           this.getRcvDefaultAddress();
            if (this.$route.query.maps) {
                this.getStoreInfo(this.$route.query.maps);
            }
        },
    }
</script>
<style lang="less" scoped>
    .el-input-number--mini {
        max-width: 100px;
    }

    /deep/ .el-input__inner {
        font-weight: bold;
    }

    .main-content {
        .cart-header {
            margin-bottom: 20px;
            text-align: center;
            background-color: black;
            color: #FFFFFF;
            padding-bottom: 20px;
        }
    }

    .cart-item {
        border-bottom-style: inset;
        border-bottom-width: 1px;
        margin: 10px auto;
        min-height: 85px;
        display: flex;
        align-items: center;
        font-size: 14px;

        &:last-child {
            border-bottom-width: 0px;
            margin-bottom: 0px;
        }
    }

    .cart-item-mobile {
        align-items: start;
        margin: 15px auto;

        .el-row {
            display: flex;
            align-items: center;
            margin-bottom: 2px;
            padding-bottom: 3px;
            border-bottom-style: inset;
            border-bottom-width: 1px;

            &:last-child {
                border-bottom-width: 0px;
                margin-bottom: 5px;
            }
        }
    }

    .cart-total-card {
        font-size: 14px;

        .cart-total-item {
            border-bottom-style: inset;
            border-bottom-width: 1px;
            margin: 20px 1%;
            padding-bottom: 10px;

            :last-child {
                text-align: right;

                span {
                    font-weight: bold;
                }
            }
        }

        .cart-total {
            border: none !important;
        }
    }

    .price {
        color: #F56C6C;

    }

    .total-amt {
        color: #a73535;
        font-weight: bold;
    }

    /*.cart-header-op{*/
    /*display: block;*/
    /*@media screen and (max-width: 768px) {*/
    /*display: none;*/
    /*}*/
    /*}*/

    .address-wrap {
        ul {
            margin: 0 auto;
            padding: 0px;
            list-style: none;

            li {
                margin: 10px auto;
                list-style: none;
            }
        }

        .address-radio {
            height: 50px;
            display: flex;
            align-items: center;
        }
    }
</style>
