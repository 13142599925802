<template>
    <div>
        <el-dialog title="收貨地址" :visible.sync="dialogFormVisible" class="dlg-address"
                   :before-close="cancelDialog"
                   @open="openDialog()"
        >
            <el-form :model="addressForm" status-icon :rules="rulesAddress"
                     ref="ruleAddressForm" key="ruleAddressForm">
                <el-form-item label="姓名" :label-width="formLabelWidth" prop="name">
                    <el-input size="small" v-model="addressForm.name" style="width: 80%"></el-input>
                </el-form-item>
                <el-form-item label="电话" :label-width="formLabelWidth" prop="mobile">
                    <el-input size="small" v-model="addressForm.mobile" style="width: 80%"
                              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="縣 / 市" :label-width="formLabelWidth" prop="city">
                    <el-select size="small" v-model="addressForm.city" placeholder="請選擇縣 / 市" @change="handleChangeCity">
                        <el-option
                                v-for="item in twCityList"
                                :key="item.cityId"
                                :label="item.name"
                                :value="item.cityId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="鄉鎮市" :label-width="formLabelWidth" prop="town">
                    <el-select size="small" v-model="addressForm.town" placeholder="請選擇鄉鎮市" @change="handleChangeTw">
                        <el-option
                                v-for="item in twTownList"
                                :key="item.cityId"
                                :label="item.name"
                                :value="item.cityId">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="郵遞區號" :label-width="formLabelWidth" prop="zip">
                    <el-input size="small" v-model="addressForm.zip" autocomplete="off" style="width: 40%"
                              oninput="value=value.replace(/^\.+|[^\d.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="地址" :label-width="formLabelWidth" prop="address">
                    <el-input size="small" v-model="addressForm.address" autocomplete="off" style="width: 95%"></el-input>
                </el-form-item>
                <el-form-item label="設為默認">
                    <el-switch v-model="addressForm.isDefault"></el-switch>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="cancelDialog">取 消</el-button>
                <el-button size="small" type="primary" @click="submitForm('ruleAddressForm')">確 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {GetAddressList, GetCityList, GetCityListById, SaveAddress} from '@/request/api.js';

    export default {
        props:['dialogFormVisible','editRow'],
        name: '',
        components: {},
        data() {
            var inputRequired = (rule, value, callback) => {
                if (!value || value === '') {
                    callback(new Error('必填'));
                }
                callback();
            };
            var checkMobile = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('請輸入正確的手機號'));
                }
                setTimeout(() => {
                    let mobile = /^(0\d{9,10})$/;
                    if (mobile.test(value) || value == '') {
                        callback();
                    } else {
                        callback(new Error('請輸入正確的手機號'));
                    }
                }, 500);
            };
            return {
                addressList: [],
                addressForm: {
                    id: 0,
                    name: '',
                    mobile: '',
                    city: '',
                    town: '',
                    zip: '',
                    address: '',
                    isDefault: false,
                },
                formLabelWidth: '80px',
                cityList: [],
                twCityList: [],
                twTownList: [],
                cityName: '',
                districtName: '',
                rulesAddress: {
                    name: [
                        {required: true, validator: inputRequired, trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true, type: 'tel', validator: checkMobile, trigger: 'blur'}
                    ],
                    zip: [
                        {required: true, type: 'number', validator: inputRequired, trigger: 'blur'},
                        {pattern: /^\d{3}$/, message: "請輸入正確的郵遞區號", trigger: "blur"}
                    ],
                    city: [
                        {required: true, validator: inputRequired, trigger: 'blur'}
                    ],
                    town: [
                        {required: true, validator: inputRequired, trigger: 'blur'}
                    ],
                    address: [
                        {required: true, validator: inputRequired, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            cancelDialog() {//右上角关闭窗口
                this.$emit('dialogVisible', false);
                this.$emit('resetEditRow');
            },
            openDialog(){//打开窗口时
                if (!this.editRow) {
                    setTimeout(()=>{
                        this.$refs['ruleAddressForm'].resetFields();
                    },100)

                }
                if (this.cityList.length > 0) {
                    if (this.editRow) {
                        this.editAddress(this.editRow);
                    }
                    return;
                }
                this.initAllCityList(this.editRow);
            },
            closeDialog(){
                /*console.log('close dialog....');
                this.$refs['ruleAddressForm'].resetFields();
                this.$emit('dialogVisible', false);*/
            },
            submitForm(formName) {
                console.log(formName, this.addressForm);
                let _this = this;
                _this.$refs[formName].validate((valid) => {
                    if (!valid) {
                        _this.$message.error('請輸入正確的資料');
                        return false;
                    }
                    SaveAddress({
                        id: this.addressForm.id,
                        realName: this.addressForm.name,
                        phone: this.addressForm.mobile,
                        detail: this.addressForm.address,
                        isDefault: this.addressForm.isDefault,
                        postCode: this.addressForm.zip,
                        address: {
                            province: '台湾省',
                            cityId: this.addressForm.city,
                            city: this.cityName,
                            districtId: this.addressForm.town,
                            district: this.districtName,
                        }
                    }).then(res => {
                        if (res.code == 200) {
                            this.$emit('dialogVisible', false);
                            _this.$refs['ruleAddressForm'].resetFields();
                            this.$message.success("更新成功！");
                            this.$emit('refreshAddressList');//刷新父窗口的地址数据列表 this.getAddressList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        console.log(err);
                        this.$message.error('網絡請求失敗，稍候請重試!');
                    });

                });
            },
            editAddress(item) {
                GetCityListById(item.cityId).then(res => {
                    if (res.code == 200) {
                        this.twTownList = res.data;
                        this.addressForm.city = item.cityId;
                        this.addressForm.town = item.districtId;
                        this.cityName = item.city;
                        this.districtName = item.district;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
                this.addressForm.id = item.id;
                this.addressForm.name = item.realName;
                this.addressForm.mobile = item.phone;
                this.addressForm.zip = item.postCode;
                this.addressForm.address = item.detail;
                this.addressForm.isDefault = item.isDefault;
            },

            /*addAddress() {
                this.$emit('dialogVisible', true);
                this.addressForm.id = 0;
                // this.$refs['ruleAddressForm'].resetFields();
                if (this.cityList.length <= 0) {
                    this.initAllCityList();
                }
            },*/
            initAllCityList(editRow) {//初始化城市数据列表
                GetCityList({}).then(res => {
                    if (res.code == 401) {
                        this.$router.push('/login');
                        return;
                    }
                    if (res.code == 200) {
                        this.cityList = res.data;
                        this.initTWData(this.cityList);
                        if (editRow) {
                            this.editAddress(editRow);
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.log(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            },
            initTWData(allCityList) {
                allCityList.forEach((item) => {
                    if (item.cityId == 714401) {
                        this.twCityList = item.child;
                        return;
                    }
                });
            },
            handleChangeCity(cityId) {
                this.cityName = cityId ? this.twCityList.find(item => item.cityId === cityId).name : '';
                this.addressForm.town = '';
                this.twCityList.forEach((item) => {
                    if (item.cityId == cityId) {
                        this.twTownList = item.child;
                        return;
                    }
                })
            },
            handleChangeTw(item) {
                this.districtName = item ? this.twTownList.find(ele => ele.cityId === item).name : '';
            },
            getAddressList() {
                GetAddressList({page: 1, limit: 10}).then(res => {
                    if (res.code == 200) {
                        this.addressList = res.data.list;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    console.error(err);
                    this.$message.error('網絡請求失敗，稍候請重試!');
                });
            }
        },
        created() {
        },
        mounted() {
        },
    }
</script>
<style lang="less" scoped>
    /deep/ .el-dialog {
        width: 45%;
        @media screen and (max-width: 768px) {
            width: 80%;
            top: -30px;
        }

        .el-form-item {
            margin-bottom: 15px;
        }
    }

    /deep/ .el-button--mini {
        padding: 7px 5px;
    }

    .content {
        font-size: 14px;
    }
</style>
